/* src/TermsAndPolicy.css */
.terms-and-policy-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    line-height: 1.6;
  }
  
  .terms-and-policy-container h1, .terms-and-policy-container h2 {
    color: #333;
  }
  
  .terms-and-policy-container p, .terms-and-policy-container ul {
    color: #555;
  }
  
  .terms-and-policy-container ul {
    padding-left: 20px;
  }
  